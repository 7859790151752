import { useIntl } from 'react-intl'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import { eventsByAreaRoute, guideByAreaRoute } from 'lib/routes'
import { useRouter } from 'next/router'
import { usePersonalizationContext } from '@/context/PersonalizationContext'
import dict from '@/messages/dict'
import eventsMessages from '@/messages/events'
import useLocalisedAreaName from '@/hooks/useLocalisedAreaName'
import useNavLinkTracking from '@/hooks/useNavLinkTracking'

const useGlobalNavItems = () => {
  const intl = useIntl()
  const { loading, area: userArea, eventsArea } = usePersonalizationContext()
  const { pathname: activePath } = useRouter()
  const trackNavClick = useNavLinkTracking()

  const eventsLinkProps = loading
    ? { href: '/events' }
    : eventsByAreaRoute(
        generateUrlForAreaAndCountry('/events', eventsArea || userArea)
      )

  const guideLinkProps = loading
    ? { href: '/guide' }
    : guideByAreaRoute(generateUrlForAreaAndCountry('/guide', userArea))

  const guideTitle = useLocalisedAreaName({
    area: userArea,
    country: userArea.country,
  })

  const events = [
    {
      title: intl.formatMessage(dict.events),
      linkProps: eventsLinkProps,
    },
    {
      title: guideTitle,
      linkProps: guideLinkProps,
    },
    {
      title: intl.formatMessage(eventsMessages.festivals),
      linkProps: { href: '/festivals' },
    },
  ]
  const music = [
    {
      title: intl.formatMessage(dict.music),
      linkProps: { href: '/music' },
    },
    {
      title: intl.formatMessage(dict.raPodcast),
      linkProps: { href: '/podcast' },
    },
    {
      title: intl.formatMessage(dict.reviews),
      linkProps: { href: '/reviews' },
    },
    {
      title: intl.formatMessage(dict.mixOfTheDay),
      linkProps: { href: '/mix-of-the-day' },
    },
    {
      title: intl.formatMessage(dict.playlists),
      linkProps: { href: '/playlists' },
    },
  ]
  const magazine = [
    {
      title: intl.formatMessage(dict.magazine),
      linkProps: { href: '/magazine' },
    },
    {
      title: intl.formatMessage(dict.features),
      linkProps: { href: '/features' },
    },
    {
      title: intl.formatMessage(dict.films),
      linkProps: { href: '/films' },
    },
    {
      title: intl.formatMessage(dict.raExchange),
      linkProps: { href: '/exchange' },
    },
    {
      title: intl.formatMessage(dict.news),
      linkProps: { href: '/news' },
    },
  ]

  const hrefIsActive = (href) => {
    const hrefPathname = href?.pathname ? href.pathname : href
    return hrefPathname === activePath
  }

  return [events, music, magazine].reduce(
    (acc, items) => [
      ...acc,
      items.reduce(
        (innerAcc, item, index) => [
          ...innerAcc,
          {
            ...item,
            active: !item.inactive && hrefIsActive(item.href),
            onClick: () => trackNavClick(item.linkProps.href, index),
          },
        ],
        []
      ),
    ],
    []
  )
}

export default useGlobalNavItems
