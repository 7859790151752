import { Alignment, variants, Text } from '@resident-advisor/design-system'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import Link from '@/components/generic/link'

const GlobalNavList = ({ items, ...props }: GlobalNavListProps) => (
  <Alignment
    as="nav"
    flexDirection="column"
    pl={3}
    borderLeftWidth="1px"
    borderLeftStyle="solid"
    borderLeftColor="grey.20"
    {...props}
  >
    <ul>
      {items.map((item, index) => {
        const { active, linkProps, title, Component, ...itemProps } = item

        return (
          <li key={title}>
            {item.linkProps?.href ? (
              <Link
                variant={variants.text.heading.l}
                color={getLinkColor(active, index)}
                {...itemProps}
                {...linkProps}
              >
                {title}
              </Link>
            ) : (
              <Text
                variant={variants.text.heading.l}
                color={getLinkColor(active, index)}
                {...itemProps}
              >
                {title}
              </Text>
            )}
            {Component && <Component />}
          </li>
        )
      })}
    </ul>
  </Alignment>
)

const getLinkColor = (active: boolean, index: number) => {
  if (index === 0) return 'primary'

  return active ? 'accent' : 'grey.30'
}

type GlobalNavListProps = {
  items: GlobalNavListItemProps[]
}

type GlobalNavListItemProps = {
  title: string
  linkProps?: {
    href: string | { pathname: string; query?: NextParsedUrlQuery }
    as?: string | { pathname: string; query?: NextParsedUrlQuery }
  }
  active?: boolean
  Component?: (props: unknown) => JSX.Element
  onClick?: () => void
}

export default GlobalNavList
