import { motion } from 'framer-motion'
import { PropsWithChildren } from 'react'
import animation from '@/themes/animation'

const variants = {
  open: {
    rotate: 180,
    transition: animation.default.open,
  },
  closed: {
    rotate: 0,
    transition: animation.default.closed,
  },
}

const Flip = ({
  animate,
  children,
}: PropsWithChildren<{ animate: 'open' | 'closed' }>) => (
  <motion.div variants={variants} animate={animate}>
    {children}
  </motion.div>
)

export default Flip
