import { Box } from '@resident-advisor/design-system'
import { PropsWithChildren } from 'react'
import useDimensions from '@/hooks/useDimensions'
import BoxProps from '@/interfaces/BoxProps'
import FullWidthStyle from './FullWidthStyle'

const FullWidth = ({
  children,
  sizes,
  ...props
}: PropsWithChildren<FullWidthProps>) => (
  <Box {...props}>
    <FullWidthStyle
      sizes={sizes}
      useAbsolutePosition={false}
      data-testid="FullWidthStyleRelative"
    >
      {children}
    </FullWidthStyle>
  </Box>
)

type FullWidthProps = {
  sizes: Array<'s' | 'm' | 'l'>
} & BoxProps

const FullWidthAbsolutePosition = ({
  children,
  sizes,
  dataTestId = 'FullWidthStyleAbsolute',
  ...props
}: PropsWithChildren<FullWidthAbsolutePositionProps>) => {
  const [ref, { height }] = useDimensions<HTMLDivElement>()

  return (
    <Box {...props} height={height}>
      <FullWidthStyle
        ref={ref}
        sizes={sizes}
        useAbsolutePosition
        data-testid={dataTestId}
      >
        {children}
      </FullWidthStyle>
    </Box>
  )
}

type FullWidthAbsolutePositionProps = {
  sizes: Array<'s' | 'm' | 'l'>
  dataTestId?: string
} & BoxProps

export { FullWidthAbsolutePosition }
export default FullWidth
