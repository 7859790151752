import { useState } from 'react'
import PropTypes from 'prop-types'
import {
  variants,
  Icon,
  ArrowDownIcon,
  Alignment,
  Box,
  colors,
} from '@resident-advisor/design-system'
import styled from 'styled-components'
import testIds from '@/enums/testIds'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import featureSwitches from '@/enums/feature-switches'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import { useMenuContext } from '@/context/MenuContext'
import { useUserContext } from '@/context/UserContext'
import Person from '@/components/generic/person'
import hoverColorStyles from '@/components/navigation/global-nav/hoverColorStyles'
import useIsMobile from '@/hooks/useIsMobile'
import Flip from '../animation/Flip'

const AuthenticatedPerson = ({
  iconSize = 40,
  hasChevron = false,
  ...props
}) => {
  const { loading, error, user } = useUserContext()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ showMobileMenuUserSettings }] = useMenuContext()
  const [isHovered, setIsHovered] = useState(false)
  const isMobile = useIsMobile()

  if (loading) return null
  if (error || !user) return null

  if (enableNewMainNav)
    return (
      <HoverAlignment
        onMouseEnter={() => {
          setIsHovered(true)
        }}
        onMouseLeave={() => {
          setIsHovered(false)
        }}
        color={colors.white}
        hoverColor={enableNewMainNav && isMobile ? colors.white : colors.coral}
      >
        <Box role="button" alignItems="center" height="auto" display="flex">
          <Person
            color="inherit"
            name={user.username}
            imageUrl={user.image}
            textVariant={props.textVariant || variants.text.small}
            size={iconSize}
            testId={testIds.authenticatedPerson}
            data-pw-test-id={PLAYWRIGHT_TEST_IDS.authenticatedPerson}
            pr={1}
            {...props}
          />
          {hasChevron && (
            <Flip animate={showMobileMenuUserSettings ? 'open' : 'closed'}>
              <Alignment display={{ s: 'block', m: 'none' }}>
                <Icon
                  Component={ArrowDownIcon}
                  color={
                    !enableNewMainNav && (isHovered ? colors.coral : 'primary')
                  }
                />
              </Alignment>
            </Flip>
          )}
        </Box>
      </HoverAlignment>
    )
  return (
    <Person
      name={user.username}
      imageUrl={user.image}
      textVariant={props.textVariant || variants.text.small}
      size={iconSize}
      testId={testIds.authenticatedPerson}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.authenticatedPerson}
      {...props}
    />
  )
}

const HoverAlignment = styled(Alignment)`
  cursor: pointer;
  ${hoverColorStyles}
`

AuthenticatedPerson.propTypes = {
  iconSize: PropTypes.number,
  textVariant: PropTypes.string,
  hasChevron: PropTypes.bool,
}

export default AuthenticatedPerson
