import { ApolloClient, useApolloClient } from '@apollo/client'
import axios, { AxiosResponse } from 'axios'
import { setCookie } from 'lib/cookieHelpers'
import Router from 'next/router'
import getConfig from 'next/config'
import cookieNames from 'lib/cookieNames'
import { logger } from 'lib/logger'
import Tracking, { TRACKING_EVENT, TRACKING_PROPERTY } from 'scripts/tracking'
import { FormikHelpers } from 'formik'
import errors from '@/messages/errors'
import AUTH_API_VERSION from '@/enums/auth-api-version'
import GET_USER_QUERY from '@/context/UserContext/queries/GetUserQuery'
import SignInType from '@/enums/signin-type'
import { isValidReturnUrl } from './helpers'

const { publicRuntimeConfig } = getConfig()

const LOGIN_ROUTE_V2 = `${publicRuntimeConfig.AUTH_SERVICE}/api/${AUTH_API_VERSION.v2}/login`
const LOGIN_ROUTE_MAGIC_LINK = `${publicRuntimeConfig.AUTH_SERVICE}/api/${AUTH_API_VERSION.v2}/login/magic-link`

type LoginValues = {
  usernameOrEmail: string
  password: string
}

type MagicLinkValues = {
  email: string
  oobCode: string
}

type LoginResponse = {
  localId: string
  dispayName?: unknown
  registered: boolean
  kind: string
  email: string
}

const useRALogin = ({
  returnUrl,
  reloadUrl = undefined,
  onSuccess = undefined,
  source,
  signInType = SignInType.UsernamePassword,
}: {
  returnUrl: string
  reloadUrl?: string
  onSuccess?: (response: AxiosResponse<LoginResponse>) => void
  source: string
  signInType?: SignInType
}) => {
  const apolloClient = useApolloClient()

  return async (
    values: LoginValues | MagicLinkValues,
    actions: Pick<
      FormikHelpers<LoginValues | MagicLinkValues>,
      'setSubmitting' | 'setStatus'
    >
  ) => {
    try {
      const method = getLoginTrackingMethod(values, signInType)
      Tracking.trackMixpanel(TRACKING_EVENT.loginAttempt, {
        'Login Method': method,
        'Login Source': source,
      })

      // Based on the signin type we choose what login route to use
      const response = await login(
        signInType === SignInType.MagicLink
          ? LOGIN_ROUTE_MAGIC_LINK
          : LOGIN_ROUTE_V2,
        values
      )

      await processLoginResponse({
        response,
        apolloClient,
        returnUrl,
        reloadUrl,
        onSuccess,
        onError: (e) => handleLoginError(e, actions),
        source,
        method,
      })
    } catch (e) {
      handleLoginError(e, actions)
    } finally {
      actions.setSubmitting(false)
    }
  }
}

const login = async (route: string, values: LoginValues | MagicLinkValues) =>
  axios.post<LoginResponse>(route, values, { withCredentials: true })

const handleLoginError = (
  e,
  actions: Pick<FormikHelpers<LoginValues | MagicLinkValues>, 'setStatus'>
) => {
  const statusCode = e.response?.status || 500
  const loginErrorCode = e.response?.data?.errorCode

  actions.setStatus({ statusCode: loginErrorCode || statusCode })
}

const statusCodeMessages = (intl, isPasswordOnlyForm) => ({
  IncorrectUsernameOrPassword: isPasswordOnlyForm
    ? intl.formatMessage(errors.incorrectPassword)
    : intl.formatMessage(errors.incorrectUsernameOrPassword),
  EmailNotVerified: intl.formatMessage(errors.emailNotVerified),
  GenericError: intl.formatMessage(errors.serverError),
  TooManyAttemptsTryLater: intl.formatMessage(errors.tooManyAttemptsTryLater),
  MagicLinkExpired: intl.formatMessage(errors.magicLinkExpired),
  MagicLinkInvalidEmail: intl.formatMessage(errors.magicLinkInvalidEmail),
  500: intl.formatMessage(errors.serverError),
})

const processLoginResponse = async ({
  response,
  apolloClient,
  onSuccess,
  onError,
  returnUrl,
  reloadUrl,
  source,
  method,
}: {
  response: AxiosResponse<LoginResponse>
  apolloClient: ApolloClient<object>
  onSuccess?: (response: AxiosResponse<LoginResponse>) => void
  onError?: (e: unknown) => void
  returnUrl: string
  reloadUrl?: string
  source: string
  method: string
}) => {
  try {
    // The refresh token is HttpOnly so we do not know in the JS code whether it is present or not
    // The getTokenHelpers code checks a flag set by the server to know - so we need to override this
    // when client side logs in
    setCookie(cookieNames.successfulLoginDuringSession, 'true')

    Tracking.trackMixpanel(TRACKING_EVENT.loginSuccess, {
      'Login Method': method,
      'Login Source': source,
    })

    // Call this here so any changes are applied
    // on the API before resetting the cache
    if (onSuccess) {
      await onSuccess(response)
    }

    if (apolloClient) {
      // Force a reload of all the current queries now that the user is
      // logged in
      await apolloClient.cache.reset()
      await apolloClient.query({ query: GET_USER_QUERY })
    }

    // Forces a reload of the page. The main reason to do this is to force feature switches to update,
    // as after login the feature switch values are not deterministic and may still have logged-out values
    // https://app.shortcut.com/resident-advisor/story/35775/unleash-client-proxy-is-initialised-twice
    if (reloadUrl) {
      // eslint-disable-next-line xss/no-location-href-assign
      window.location.href = `${window.origin}${reloadUrl}`
    } else if (returnUrl) {
      Router.replace(isValidReturnUrl(returnUrl) ? returnUrl : '/')
    }
  } catch (e) {
    logger.error(`Error processing login response: ${e}`)

    if (onError) {
      onError(e)
    }
  }
}

const getLoginTrackingMethod = (
  values: LoginValues | MagicLinkValues,
  signInType: SignInType
) => {
  if (signInType === SignInType.MagicLink) {
    return TRACKING_PROPERTY.loginMethod.magicLink
  }

  if (
    'usernameOrEmail' in values &&
    typeof values.usernameOrEmail === 'string'
  ) {
    return values.usernameOrEmail.includes('@')
      ? TRACKING_PROPERTY.loginMethod.email
      : TRACKING_PROPERTY.loginMethod.username
  }

  return TRACKING_PROPERTY.loginMethod.unknown
}

export default useRALogin
export {
  handleLoginError,
  useRALogin,
  processLoginResponse,
  statusCodeMessages,
  getLoginTrackingMethod,
}
export type { LoginValues, MagicLinkValues, LoginResponse }
