import { IntlShape, defineMessages, useIntl } from 'react-intl'
import AreaDto from '@/interfaces/gql/AreaDto'
import CountryDto from '@/interfaces/gql/CountryDto'
import areas from '@/messages/areas'
import locations from '@/messages/locations'

const localisedAreaName = ({ area, country }, intl): string => {
  if (!area?.name || !country?.name) {
    return ''
  }

  const countryKey = country.urlCode?.toLowerCase()
  const areaKey = area.urlName?.toLowerCase()

  if (!areaKey || area.name?.toLowerCase() === 'all') {
    return localise(countryKey, locations, country.name, intl)
  }

  const areaDict = areas[countryKey]

  return localise(areaKey, areaDict, area.name, intl)
}

const localise = (
  key: string,
  dict = defineMessages({}),
  defaultName: string,
  intl: IntlShape
): string => {
  if (dict[key]) {
    return intl.formatMessage(dict[key])
  }

  return defaultName ?? key
}

const useLocalisedAreaName = ({
  area,
  country,
}: {
  area: AreaDto
  country: CountryDto
}) => {
  const intl = useIntl()

  return localisedAreaName({ area, country }, intl)
}

export default useLocalisedAreaName
