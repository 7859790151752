import { useCallback } from 'react'
import {
  Alignment,
  BurgerIcon,
  RAIcon,
  SearchIcon,
  colors,
  Icon,
  Box,
} from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { generateUrlForAreaAndCountry } from 'lib/utils'
import RAProHorizontal from 'public/static/ra-pro-logo-horizontal.svg'
import styled from 'styled-components'
import Tracking, { TRACKING_EVENT } from 'scripts/tracking'
import { usePersonalizationContext } from '@/context/PersonalizationContext'
import dict from '@/messages/dict'
import { useMenuContext } from '@/context/MenuContext'
import buttonTrackingIds from '@/tracking/button-tracking-ids'
import featureSwitches from '@/enums/feature-switches'
import {
  useFeatureSwitch,
  useFeatureSwitchData,
} from '@/context/FeatureSwitchesContext'
import sections from './sections'
import GlobalNavItem from './GlobalNavItem'
import GlobaNavUserItem from './GlobalNavUserItem'
import {
  GlobalNavLink,
  showFeatureSwitchLink,
  LinkPayload,
} from './GlobalNavBarNew'
import hoverColorStyles from './hoverColorStyles'

const GlobalNavBarOld = ({
  toggleShowSearch,
  toggleMenuSection,
  navColor,
  beyondElement,
  showMobileGlobalNav,
}: GlobalNavBarOldProps) => {
  const intl = useIntl()
  const { loading, area: userArea, eventsArea } = usePersonalizationContext()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)
  const [{ hoverColor }] = useMenuContext()
  const navHoverColor = beyondElement ? colors.coral : hoverColor

  const showMainMenu = useCallback(
    () => toggleMenuSection(sections.main),
    [toggleMenuSection]
  )
  const showUserMenu = useCallback(
    () => toggleMenuSection(sections.user),
    [toggleMenuSection]
  )

  const contentAreas = getContentAreas(loading, userArea, eventsArea)

  const fourthLinkInGlobalNav = useFeatureSwitchData<LinkPayload>(
    featureSwitches.enableFourthLinkWithPayloadInGlobalNav
  )
  const showFourthLinkInGlobalNav = showFeatureSwitchLink(fourthLinkInGlobalNav)

  if (enableNewMainNav) return null
  return (
    <div data-testid="old-global-nav">
      <Alignment
        display={{ s: showMobileGlobalNav ? 'flex' : 'none', m: 'flex' }}
        justifyContent="space-between"
        py={{ s: 2, m: 1 }}
      >
        <Alignment
          alignItems="center"
          justifyContent={{ s: 'space-between', m: undefined }}
          width={{ s: '100%', m: 'auto' }}
        >
          <Box display={{ s: 'none', m: 'block' }}>
            <GlobalNavLink href="/">
              <HoverIcon
                size={50}
                Component={RAIcon}
                color={navColor}
                mr={3}
                hoverColor={navHoverColor}
              />
            </GlobalNavLink>
          </Box>
          <Alignment alignItems="center">
            {contentAreas.map(({ key, ...linkProps }) => (
              <GlobalNavLink key={key} {...linkProps}>
                <GlobalNavItem color={navColor} hoverColor={navHoverColor}>
                  {intl.formatMessage(dict[key])}
                </GlobalNavItem>
              </GlobalNavLink>
            ))}
            {showFourthLinkInGlobalNav && (
              <Box display={{ s: 'none', m: 'block' }}>
                <GlobalNavLink
                  href={fourthLinkInGlobalNav.payload.url}
                  onClick={() => {
                    Tracking.trackMixpanel(
                      TRACKING_EVENT.fourthNavLinkClicked,
                      {
                        'Nav Version': 'Old',
                        'Link Clicked': fourthLinkInGlobalNav.payload.name,
                      }
                    )
                  }}
                >
                  <GlobalNavItem color={navColor} hoverColor={navHoverColor}>
                    {fourthLinkInGlobalNav.payload.name}
                  </GlobalNavItem>
                </GlobalNavLink>
              </Box>
            )}
          </Alignment>
          <HoverIcon
            color={navColor}
            Component={SearchIcon}
            mx={2}
            onClick={toggleShowSearch}
            css={{ cursor: 'pointer' }}
            hoverColor={navHoverColor}
            data-button-tracking-id={buttonTrackingIds.openNavSearchModal}
          />
        </Alignment>

        <Alignment alignItems="center" display={{ s: 'none', m: 'flex' }}>
          <GlobaNavUserItem
            color={navColor}
            onClick={showUserMenu}
            hoverColor={navHoverColor}
          />
          <GlobalNavLink href="/pro">
            <HoverIcon
              mx={3}
              height={14}
              width={60}
              Component={RAProHorizontal}
              color={navColor}
              hoverColor={navHoverColor}
            />
          </GlobalNavLink>

          <HoverIcon
            color={navColor}
            Component={BurgerIcon}
            css={{ cursor: 'pointer' }}
            onClick={showMainMenu}
            data-button-tracking-id={buttonTrackingIds.openNavModal}
            mx={2}
            hoverColor={navHoverColor}
          />
        </Alignment>
      </Alignment>
    </div>
  )
}

const getContentAreas = (loading, userArea, eventsArea) => {
  const areaToUse = eventsArea || userArea

  return [
    {
      key: 'events',
      href: loading
        ? '/events'
        : generateUrlForAreaAndCountry('/events', areaToUse),
    },
    { key: 'music', href: '/music' },
    { key: 'magazine', href: '/magazine' },
  ]
}

type GlobalNavBarOldProps = {
  toggleShowSearch: () => null
  toggleMenuSection: (string) => null
  navColor: string
  beyondElement?: boolean
  showMobileGlobalNav?: boolean
}

const HoverIcon = styled(Icon)`
  ${hoverColorStyles}
`

export default GlobalNavBarOld
