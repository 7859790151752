import getConfig from 'next/config'

const isValidReturnUrl = (url: string): boolean => {
  const {
    publicRuntimeConfig: { ALLOWED_DOMAINS },
  } = getConfig()

  try {
    // check if relative url
    if (url.startsWith('/')) {
      return true
    }

    const urlObj = new URL(url)
    const { hostname } = urlObj

    // Check if hostname matches any main domain or is a subdomain of any
    const isDomainOrSubdomain = ALLOWED_DOMAINS.some(
      (domain: string) => hostname === domain || hostname.endsWith(`.${domain}`)
    )

    return isDomainOrSubdomain
  } catch (error) {
    return false
  }
}

export default isValidReturnUrl
