import styled, { css } from 'styled-components'
import { deviceWidths as media } from '@resident-advisor/design-system'

const FullWidthStyle = styled.div<{
  sizes: Array<'s' | 'm' | 'l'>
  useAbsolutePosition: boolean
}>`
  ${({ sizes, useAbsolutePosition }) =>
    sizes.includes('s')
      ? applyFullWidthStyle(useAbsolutePosition)
      : applyNormalWidthStyle(useAbsolutePosition)};

  ${media.m`
    ${({ sizes, useAbsolutePosition }) =>
      sizes.includes('m')
        ? applyFullWidthStyle(useAbsolutePosition)
        : applyNormalWidthStyle(useAbsolutePosition)};
  `};

  ${media.l`
    ${({ sizes, useAbsolutePosition }) =>
      sizes.includes('l')
        ? applyFullWidthStyle(useAbsolutePosition)
        : applyNormalWidthStyle(useAbsolutePosition)};
  `};
`

const applyFullWidthStyle = (useAbsolutePosition) =>
  useAbsolutePosition
    ? applyFullWidthPositionAbsolute
    : applyFullWidthPositionRelative

const applyFullWidthPositionAbsolute = css`
  left: 0;
  position: absolute;
  width: 100vw;
  max-width: 100%;
`

// https://css-tricks.com/full-width-containers-limited-width-parents/#aa-no-calc-needed
const applyFullWidthPositionRelative = css`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
`

const applyNormalWidthStyle = (useAbsolutePosition) =>
  useAbsolutePosition
    ? applyNormalWidthPositionAbsolute
    : applyNormalWidthPositionRelative

const applyNormalWidthPositionAbsolute = css`
  left: auto;
  position: relative;
  width: 100%;
`

const applyNormalWidthPositionRelative = css`
  left: auto;
  right: auto;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
`

export default FullWidthStyle
