import PropTypes from 'prop-types'
import { useEffect } from 'react'
import styled, { css } from 'styled-components'
import { StickyContainer, Sticky } from 'react-sticky'
import get from 'lodash/get'
import useScrollDirection from '@/hooks/useScrollDirection'
import { zIndex } from '@/themes'
import { useFeatureSwitch } from '@/context/FeatureSwitchesContext'
import featureSwitches from '@/enums/feature-switches'

const StickyNav = ({ children, showMenu }) => {
  const { scrollDirection, setScrollDirection } = useScrollDirection()
  const enableNewMainNav = useFeatureSwitch(featureSwitches.enableNewMainNav)

  useEffect(() => {
    const clickListener = (event) => {
      const hasAnchorHash =
        get(event, 'target.hash') || get(event, 'target.parentNode.hash')

      const isLink =
        get(event, 'target.nodeName') === 'A' ||
        get(event, 'target.parentNode.nodeName') === 'A'

      if (hasAnchorHash && isLink) {
        setScrollDirection(null)

        // After tick (i.e. browser moved)
        setTimeout(() => {
          setScrollDirection(null)
        }, 1)
      }
    }

    window.addEventListener('click', clickListener)

    return () => window.removeEventListener('click', clickListener)
  }, [setScrollDirection])

  useEffect(() => {
    if (showMenu) {
      setScrollDirection(null)
    }
  }, [setScrollDirection, showMenu])

  return (
    <StickyContainer style={{ height: 0 }}>
      <Sticky disableCompensation>
        {({ distanceFromTop, calculatedHeight }) => {
          const beyondElement = isBeyondElement(
            scrollDirection,
            distanceFromTop,
            calculatedHeight,
            enableNewMainNav
          )
          const shouldShowNav = isStickyAndVisible(
            beyondElement,
            scrollDirection,
            enableNewMainNav
          )

          return (
            <NavWrapper
              beyondElement={beyondElement && !showMenu}
              shouldShowNav={shouldShowNav && !showMenu}
              calculatedHeight={calculatedHeight}
              enableNewMainNav={enableNewMainNav}
            >
              {children({
                beyondElement,
              })}
            </NavWrapper>
          )
        }}
      </Sticky>
    </StickyContainer>
  )
}

const isBeyondElement = (
  scrollDirection,
  distanceFromTop,
  calculatedHeight,
  enableNewMainNav
) => {
  if (!enableNewMainNav && scrollDirection === 'down') {
    return distanceFromTop + calculatedHeight < 0
  }
  return distanceFromTop < 0
}

const isStickyAndVisible = (
  beyondElement,
  scrollDirection,
  enableNewMainNav
) => {
  return enableNewMainNav || (beyondElement && scrollDirection === 'up')
}

StickyNav.propTypes = {
  showMenu: PropTypes.bool.isRequired,
  children: PropTypes.func.isRequired,
}

const NavWrapper = styled.div`
  position: relative;
  z-index: ${zIndex.globalNavMenuHidden};

  ${({ beyondElement, calculatedHeight, theme }) =>
    beyondElement &&
    css`
      position: fixed;
      top: -${calculatedHeight}px;
      bottom: auto;
      width: 100%;
      transition: transform ${theme.animation.default.duration}s linear;
    `}

  ${({ beyondElement, enableNewMainNav }) =>
    enableNewMainNav &&
    beyondElement &&
    css`
      position: fixed;
      top: 0;
      visibility: visible;
      bottom: auto;
      width: 100%;
    `}

  ${({ shouldShowNav, enableNewMainNav }) =>
    shouldShowNav &&
    !enableNewMainNav &&
    css`
      transform: translate(0%, 100%);
    `}
`

export { isBeyondElement, isStickyAndVisible }
export default StickyNav
