import { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box } from '@resident-advisor/design-system'
import styledSystemPropTypes from '@styled-system/prop-types'
import useDimensions from '@/hooks/useDimensions'

import testIds from '@/enums/testIds'
import ResponsiveImage from '@/components/generic/responsive-image'
import FullWidthStyle from './FullWidthStyle'

const FullWidthImage = ({ src, alt, sizes, spacing, imageSizes, ...props }) => {
  const [fullWidthHeight, setFullWidthHeight] = useState(0)
  const [imgRef, { height: imgHeight }] = useDimensions()
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    setFullWidthHeight(imgHeight || imgRef?.current?.clientHeight)
  }, [loaded, imgHeight, imgRef])

  return (
    <Box
      data-testid={testIds.fullWidthImage}
      height={fullWidthHeight}
      {...spacing}
    >
      <FullWidthStyle sizes={sizes} useAbsolutePosition>
        <ResponsiveImage
          ref={imgRef}
          // have to set this as otherwise we need to pass
          // an aspect ratio down wherever we are using it
          useLazy={false}
          url={src}
          alt={alt}
          onImageLoad={() => setLoaded(true)}
          sizes={imageSizes}
          {...props}
        />
      </FullWidthStyle>
    </Box>
  )
}

FullWidthImage.propTypes = {
  alt: PropTypes.string,
  sizes: PropTypes.arrayOf(PropTypes.oneOf(['s', 'm', 'l'])).isRequired,
  src: PropTypes.string.isRequired,
  spacing: PropTypes.shape(styledSystemPropTypes.space),
  imageSizes: PropTypes.shape({
    s: PropTypes.string,
    m: PropTypes.string,
    l: PropTypes.string,
    xl: PropTypes.string,
  }),
}

export default FullWidthImage
