import { colors, Alignment } from '@resident-advisor/design-system'
import { useIntl } from 'react-intl'
import { Dispatch } from 'react'
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useMenuContext } from '@/context/MenuContext'
import useNavLinkTracking from '@/hooks/useNavLinkTracking'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import NavLinkItem, { NavLinkItemType } from './NavLinkItem'

const GlobalNavDropdown = ({
  dropdownSubSections,
  setHoverItem,
  offsetLeft,
}: GlobalNavDropdownProps) => {
  const intl = useIntl()
  const [{ globalNavHeight }] = useMenuContext()
  const trackNavClick = useNavLinkTracking()

  return (
    <MotionWrapper
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{
        ease: 'easeOut',
        duration: 0.1,
      }}
      exit={{ y: -110 }}
      custom={{ globalNavHeight }}
      data-pw-test-id={PLAYWRIGHT_TEST_IDS.navDropDown}
    >
      <Alignment
        backgroundColor={colors.black}
        width="100%"
        position="absolute"
        flexDirection="column"
        left={0}
        pb={{ m: 2 }}
      >
        <Alignment
          flexDirection="column"
          ml={offsetLeft}
          mr="auto"
          onMouseLeave={() => setHoverItem(null)}
        >
          <motion.div
            key={dropdownSubSections[0]?.key.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              ease: 'easeOut',
              duration: 0.1,
              delay: 0.1,
            }}
          >
            {dropdownSubSections.map((section) => (
              <NavLinkItem
                navLinkItemType={NavLinkItemType.Dropdown}
                href={section.href}
                text={intl.formatMessage(section.key)}
                onClick={() => trackNavClick(section.href)}
              />
            ))}
          </motion.div>
        </Alignment>
      </Alignment>
    </MotionWrapper>
  )
}

const MotionWrapper = styled(motion.div)`
  width: 100vw;
  position: absolute;
  left: 0;
  z-index: -1;

  ${({ custom }) => css`
    top: ${custom.globalNavHeight}px;
  `};
`

type GlobalNavDropdownProps = {
  dropdownSubSections: DropdownSectionsProps[]
  setHoverItem: Dispatch<string>
  offsetLeft: number
}

type DropdownSectionsProps = {
  key: {
    id: string
    defaultMessage: string
  }
  href: string
}

export default GlobalNavDropdown
