import { useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import {
  Alignment,
  Box,
  variants,
  Icon,
  PersonIcon,
} from '@resident-advisor/design-system'
import { AnimatePresence } from 'framer-motion'
import styled from 'styled-components'
import { TRACKING_PROPERTY } from 'scripts/tracking'
import LoginModal from '@/components/auth/modals/login/LoginModal'
import AuthenticatedPerson from '@/components/shared/authenticated-person'
import { useUserContext } from '@/context/UserContext'
import messages from '@/messages/auth'
import testIds from '@/enums/testIds'
import PLAYWRIGHT_TEST_IDS from '@/enums/playwright-test-ids'
import buttonTrackingIds from '@/tracking/button-tracking-ids'
import { PERSON_COMPONENT_TYPE } from '@/components/generic/person/Person'
import hoverColorStyles from './hoverColorStyles'
import GlobalNavItem from './GlobalNavItem'

const GlobaNavUserItem = ({ onClick, color, hoverColor, type }) => {
  const intl = useIntl()
  const { user } = useUserContext()
  const [showLoginModal, setShow] = useState(false)

  const toggleShow = () => {
    setShow(!showLoginModal)
  }

  return user ? (
    <HoverAlignment
      onClick={onClick}
      color={color}
      hoverColor={hoverColor}
      data-button-tracking-id={buttonTrackingIds.openUserNavModal}
    >
      <AuthenticatedPerson
        textVariant={variants.text.small}
        color="inherit"
        fontWeight="bold"
        iconSize={30}
        hasNotifications={!!user?.newMessagesCount}
        type={type}
      />
    </HoverAlignment>
  ) : (
    <>
      <Box
        onClick={toggleShow}
        css={{ cursor: 'pointer' }}
        data-test-id={testIds.myAccount}
        data-pw-test-id={PLAYWRIGHT_TEST_IDS.myAccount}
        data-button-tracking-id={buttonTrackingIds.openLoginModal}
      >
        <GlobalNavItem
          color={color}
          Icon={<Icon Component={PersonIcon} size={24} />}
        >
          {intl.formatMessage(messages.myAccount)}
        </GlobalNavItem>
      </Box>
      <AnimatePresence>
        {showLoginModal && (
          <LoginModal
            toggleShow={toggleShow}
            source={TRACKING_PROPERTY.loginSource.myAccount}
          />
        )}
      </AnimatePresence>
    </>
  )
}

const HoverAlignment = styled(Alignment)`
  cursor: pointer;
  ${hoverColorStyles}
`

GlobaNavUserItem.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string.isRequired,
  hoverColor: PropTypes.string,
  type: PropTypes.oneOf(Object.values(PERSON_COMPONENT_TYPE)),
}

export default GlobaNavUserItem
